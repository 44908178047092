import Client from 'shopify-buy/index.unoptimized.umd';
import { shopifyURL } from './config';

const shopifyClient = Client.buildClient({
  domain: shopifyURL,
  storefrontAccessToken: document.body.dataset.shopifyStorefrontApiKey,
  apiVersion: '2023-10',
});

const ShopifyService = {
  buildQuery(args) {
    return shopifyClient.graphQLClient.query((root) => {
      root.addConnection('products', {args: args}, (product) => {
        product.add('id');
        product.add('availableForSale');
        product.add('createdAt');
        product.add('updatedAt');
        product.add('descriptionHtml');
        product.add('description');
        product.add('handle');
        product.add('productType');
        product.add('title');
        product.add('vendor');
        product.add('publishedAt');
        product.add('onlineStoreUrl');
        product.add('options', function (options) {
          options.add('name');
          options.add('values');
        });
        product.add('images', {
          args: {
            first: 250
          }
        }, function (images) {
          images.add('pageInfo', function (pageInfo) {
            pageInfo.add('hasNextPage');
            pageInfo.add('hasPreviousPage');
          });
          images.add('edges', function (edges) {
            edges.add('cursor');
            edges.add('node', function (node) {
              node.add('id');
              node.add('src');
              node.add('altText');
            });
          });
        });
        product.add('variants', {
          args: {
            first: 250
          }
        }, function (variants) {
          variants.add('pageInfo', function (pageInfo) {
            pageInfo.add('hasNextPage');
            pageInfo.add('hasPreviousPage');
          });
          variants.add('edges', function (edges) {
            edges.add('cursor');
            edges.add('node', function (node) {
              node.add('id');
              node.add('title');
              node.add('price', function (price) {
                price.add('amount');
                price.add('currencyCode');
              });
              // node.add('priceV2', function (priceV2) {
              //   priceV2.add('amount');
              //   priceV2.add('currencyCode');
              // });
              // node.add('presentmentPrices', {
              //   args: {
              //     first: 20
              //   }
              // }, function (presentmentPrices) {
              //   presentmentPrices.add('pageInfo', function (pageInfo) {
              //     pageInfo.add('hasNextPage');
              //     pageInfo.add('hasPreviousPage');
              //   });
              //   presentmentPrices.add('edges', function (edges) {
              //     edges.add('node', function (node) {
              //       node.add('price', function (price) {
              //         price.add('amount');
              //         price.add('currencyCode');
              //       });
              //     });
              //   });
              // });
              node.add('weight');
              node.add('availableForSale', {
                alias: 'available'
              });
              node.add('sku');
              node.add('compareAtPrice', function (compareAtPrice) {
                compareAtPrice.add('amount');
                compareAtPrice.add('currencyCode');
              });
              // node.add('compareAtPriceV2', function (compareAtPriceV2) {
              //   compareAtPriceV2.add('amount');
              //   compareAtPriceV2.add('currencyCode');
              // });
              node.add('image', function (image) {
                image.add('id');
                image.add('originalSrc', {
                  alias: 'src'
                });
                image.add('altText');
              });
              node.add('selectedOptions', function (selectedOptions) {
                selectedOptions.add('name');
                selectedOptions.add('value');
              });
            });
          });
        });
      });
    });
  },
  async fetchQuery(baseArgs, ids, systemProducts) {
    if (ids.length || systemProducts) {
      if(!ids.length) {
        return [];
      }
      const idsQuery = ids.map(id => `id:"${id}"`).join(' OR ');
      const args = {
        ...baseArgs,
        query: baseArgs.query ? `${baseArgs.query} AND (${idsQuery})` : idsQuery
      };

      try {
        const response = await shopifyClient.product.fetchQuery(args);
        return response; // This should return all products matching the IDs
      } catch (error) {
        console.error(`Error fetching products:`, error);
        return [];
      }
    } else {
      try {
        const response = await shopifyClient.product.fetchQuery(baseArgs);
        return response;
      } catch (error) {
        console.error(`Error fetching products:`, error);
        return [];
      }
    }
  },

  fetchNextPage(nodes, args) {
    return shopifyClient.fetchNextPage(nodes, args);
  },
  fetchProducts(args) {
    const productsQuery = this.buildQuery(args);
    // Call the send method with the custom products query
    return shopifyClient.graphQLClient.send(productsQuery);
  },
  fetchAllProducts() {
    // Fetch all products in your shop
    return shopifyClient.product.fetchAll().then((products) => {
      // Do something with the products
      console.log('All - ', products);
    });
  },
  fetchMultiple (ids) {
    return shopifyClient.product.fetchMultiple(ids);
  },
  fetch(id) {
    return shopifyClient.product.fetch(id);
  }
};

export default ShopifyService;