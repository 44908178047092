<template>
  <div id="group2">
    <h4>Build Your Own Regimen</h4>
    <p>
      Find any product on RegimenPro<sup>®</sup> and add it to your client's regimen
    </p>
    <div class="wrap-table border-table pos-rel">
      <div
        class="tool-bar-search"
      >
        <div class="form-group">
          <div>
            <div class="filter-search">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="29"
                height="29"
                viewBox="0 0 29 29"
              >
                <metadata><?xpacket begin=" " id="W5M0MpCehiHzreSzNTczkc9d"?>
                  <x:xmpmeta
                    xmlns:x="adobe:ns:meta/"
                    x:xmptk="Adobe XMP Core 5.6-c140 79.160451, 2017/05/06-01:08:21"
                  >
                    <rdf:RDF xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
                      <rdf:Description rdf:about />
                    </rdf:RDF>
                  </x:xmpmeta><?xpacket end="w"?>
                </metadata>
                <image
                  width="29"
                  height="29"
                  xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAdCAQAAAD8mq+EAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQfjCQIKKCCQuWLCAAACVUlEQVQ4y53UTWgTQRQH8H/GJoYkTWOJMY1axESL4heFUAl4kapBSsCIevASqgcR8eBBrYhSRIIeBEFBkGAvHopECSoJRDyZitUiWlD6EaSUNsZYNWnz1bh5HkbJbnazEGcub97Ob/ftzsxqCNL2y/Jy74edafuSyfbNMbfnlXuUVaHcSNSnXceGWyqSFNnSt86V9AR5F4U3z2srUJpDzuTHHfK0hhdMrD80FOB1dE32PXNNtS6lbaM9UW/ODADGQvjwgZhiwQNBHmz5HPWK77xoujq4sgwCGfPj2xQKjnn5yBdZNMkLG/GszoBAm6bKujoqsO3jIJAnofw5CAkPf/Lts3U04gOBtJXkRmVIIFy+BgJ1pKpMQgMPQKCT9xtDQs5szINAI55ajgHx/QBw5BFUWmvOGwOAeG8tx4il7ADgfgfV5n4LALPrRVRgVQZof6/6oU7XfAWAtF1E+Q6ttBQN6pRvDUNBQttyADDtUqeTXQDgmBdRwD0KAE/71OnzgwDgSUg24p0zINC62aK+8eKE/SCQvrjQLlnXjNWcBYEGgo3gT4szCQL1h2R7+PolPhoKKMG8YV+cH4AvG2S0pO95w8cXbhQMUvhpa/cYD0/fVTzq847OGR6unRu8MtadNQssZY/4jj9cIfybq62E/Yp/iZR992vxTTWimJHlL37sV6CEkj54sS0rf9ld7xOeic0dqXpcN2+h/d4pb9Sa0RCICc7kidCLXn5FjhssR1n33Sowaa4eq5xReZfipqgUN0lr+Ohw05TjQ0+Wdf9BCTOdyzrCHxW1lyJMyp75AAAAAElFTkSuQmCC"
                />
              </svg>
              <input
                v-model="search"
                type="text"
                class="form-control search"
                placeholder="Search"
                @input="searchProduct"
              >
            </div>
          </div>
        </div>
      </div>
      <hr>

      <div class="filter">
        <div class="form form-inline form-gray">
          <!-- Filter Header -->
          <div class="filter-row">
            <label
              for="filter"
              class="form-label"
            >
              <h4>Filter</h4>
            </label>
          </div>

          <!-- Radio Buttons -->
          <div class="custom-form form-filter">
            <div class="form1-items">
              <label>
                <input
                  v-model="productsType"
                  type="radio"
                  value=""
                  name="productsType"
                >
                <span class="type-heading">All Products</span>
              </label>
            </div>
            <div class="form1-items">
              <label>
                <input
                  v-model="productsType"
                  type="radio"
                  value="presetProducts"
                  name="productsType"
                >
                <span class="type-heading">Preset Regimens</span>
              </label>
            </div>
            <div
              v-if="!preset && quizTaken && canDoctorView"
              class="form1-items"
            >
              <label>
                <input
                  v-model="productsType"
                  type="radio"
                  value="rlRecommendations"
                  name="productsType"
                >
                <span class="type-heading">RL Regimen</span>
              </label>
            </div>
            <div
              v-if="!preset"
              class="form1-items"
            >
              <label>
                <input
                  v-model="productsType"
                  type="radio"
                  value="topPicks"
                  name="productsType"
                >
                <span class="type-heading">Top Picks</span>
              </label>
            </div>
          </div>

          <div
            v-if="productsType == 'presetProducts'"
            class="custom-form"
          >
            <div class="form1-items">
              <select
                v-model="concernSelected"
                class="select-sm"
              >
                <option
                  value
                  selected
                >
                  All Concerns
                </option>
                <option
                  v-for="tag in regimenTags"
                  :key="tag.name"
                  :value="tag.name"
                >
                  {{ tag.value }}
                </option>
              </select>
            </div>
            <div class="form1-items">
              <select
                v-model="creators"
                class="select-sm"
              >
                <option
                  value
                  selected
                >
                  All Creators
                </option>
                <option
                  v-if="getPracticeId()"
                  :value="getPracticeId()"
                >
                  Practice Created
                </option>
                <option
                  value="superadmin"
                >
                  RegimenPro® Created
                </option>
              </select>
            </div>
          </div>

          <!-- First Column -->
          <div class="custom-form">
            <div
              v-if="productsType != 'presetProducts'"
              class="form2-items"
            >
              <filter-select
                v-model="brand"
                :items="brands"
                default-value="All Brands"
                name="brands-select"
              />
            </div>
            <div
              v-if="productsType != 'presetProducts'"
              class="form2-items"
            >
              <filter-select
                v-model="concern"
                :items="concerns"
                default-value="All Concerns"
                name="concerns-select"
              />
            </div>
            <div
              v-if="productsType != 'presetProducts'"
              class="form2-items"
            >
              <filter-select
                v-model="category"
                :items="categories"
                default-value="All Categories"
                name="categories-select"
              />
            </div>
          </div>

          <!-- Second Column -->
          <div class="custom-form">
            <div
              v-if="productsType != 'presetProducts'"
              class="form2-items"
            >
              <filter-select
                v-model="ingredient"
                :items="ingredients"
                default-value="All Ingredients"
                name="ingredients-select"
              />
            </div>
          </div>
        </div>
        <!-- Sort Option -->
        <div
          v-if="!systemProducts"
          class="row sort-option mt-3"
        >
          <label
            for="filter"
            class="form-label ml-4"
          >
            <h5>Sort by</h5>
          </label>
          <div class="ml-3 p-0">
            <select
              v-model="sortKeyOption"
              name="alphabetically-select"
            >
              <option
                v-for="sortOption in sortValues"
                :key="sortOption.value"
                :value="sortOption.value"
              >
                {{ sortOption.label }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <hr class="mb-2">
      <!-- <p class="mb-2">Showing 652 results</p> -->
      <!-- <p
        v-if="allProductsModel.length"
        class="mb-2"
      >
        {{ allProductsModel.length }} products loaded
      </p> -->

      <PresetRegimen
        v-if="productsType == 'presetProducts'"
        :header="'Select A Preset Regimen'"
        :patient="patient"
        :creators="creators"
        :concerns="concernSelected"
        :selected-products="regimen.products"
        :selected-list="selectedProducts"
        @update:selectedProducts="handleSelectedProductsUpdate"
        @update:selectedProduct="handleSelectedProductUpdate"
      />

      <div v-else>
        <div
          v-if="sending"
          class="preloader"
        >
          <i />
        </div>
        <transition-group
          name="list"
          tag="div"
        >
          <div
            v-for="product in products"
            :key="product.id"
            class="row row-group btn-sm-actions"
          >
            <div class="col-sm-2">
              <div class="md-image">
                <img
                  v-if="product.images.length && product.images[0].src"
                  :src="product.images[0].src"
                  alt="img"
                >
              </div>
            </div>
            <div class="col-sm-8">
              <div class="row-large">
                <div class="col-lg-5">
                  <strong>
                    {{ product.title }}
                  </strong>
                </div>
                <div class="col-lg-4 vendor">
                  {{ product.vendor }}
                </div>
                <div class="col-lg-3">
                  ${{ formatStorefrontMoney(product.variants[0].price.amount) }}
                </div>
              </div>
            </div>
            <div class="col-sm-2 pr-0 text-right">
              <a
                v-if="!isProductFullyAdded(product)"
                class="btn btn-primary hover-btn"
                @click.prevent="removeProduct(`${product.id}`)"
              >
                Add
              </a>
              <span
                v-if="isProductFullyAdded(product)"
                class="product-added"
              >
                Added
              </span>
            </div>
          </div>
        </transition-group>

        <div
          v-if="!products.length && !sending"
          class="no-products"
        >
          No products found
        </div>
        <div class="pagination-wrap">
          <div class="pagination-l">
            <div class="page-results">
              Display
              <select
                v-model="productsPerPage"
                @change="changePerPage"
              >
                <option value="20">
                  20
                </option>
                <option value="30">
                  30
                </option>
                <option value="40">
                  40
                </option>
                <option value="50">
                  50
                </option>
              </select>
              results per page
            </div>
          </div>
          <div class="pagination-r">
            <nav class="pagination">
              <!-- <p class="pag-viewing">Viewing 1 - 20 of 150</p> -->
              <ul class="pagination">
                <li>
                  <a
                    href="#"
                    :disabled="!hasPreviousPage"
                    @click.prevent="showPrevProducts"
                  >
                    <i class="fas fa-angle-left" />
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    :disabled="!hasNextPage"
                    @click.prevent="getMoreProducts"
                  >
                    <i class="fas fa-angle-right" />
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .filter-row {
    width: 100%;
  }
  .vendor {
    word-break: break-all;
  }
  .form-inline {
    align-items: baseline;
  }
  .form-filter {
    margin-top: 1rem;
    border: solid 1px #dbdadb;
    border-radius: 7px;
    padding-left: 1em;
    padding-top: 0.1em;
  }
  .custom-form {
    display: flex;
    flex-direction: column;
    width: 11rem;
    margin-left: 1rem;
    .form1-items {
      margin-bottom: 1em;
      margin-top: 0.6em;
    }
    .form2-items {
      margin-bottom: 0.5em;
      margin-top: 0.5em;
    }
  }
  .type-heading {
    margin-left: 1em;
  }
  .sort-option {
    align-items: baseline !important;
  }
</style>

<script>
import productList from '@/mixins/productList';
import { formatStorefrontMoney } from '@/helper';
import PresetRegimen from '../partials/PresetRegimen';
import { tags } from '@/store/regimen/initialObject';
import { PatientsService } from '@/services/api.service';
// import productList from '@/mixins/productListWithAlgoliaSearch';

// @group Partials
export default {
  name: 'BuildRegimen',
  components: {
    PresetRegimen,
  },
  mixins: [productList],
  props: {
    // @vuese
    // Patient object
    patient: {
      type: Object,
      default() {
        return {};
      }
    },
    preset: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      patientId: null,
      quizTaken: false,
      canDoctorView: false,
      productIds: [],
      productsType: 'topPicks',
      creators: 'nan',
      concernSelected: '',
      regimenTags: tags,
      selectedList: []
    };
  },

  beforeMount() {
    this.patientId = this.$route.params.id;
    this.fetchQuizResults();
    if(this.preset) {
      this.productsType = '';
    }
  },

  methods: {
    formatStorefrontMoney: formatStorefrontMoney,

    async getPatientQuizResults(patientId) {
      try {
        const response = await PatientsService.getPatientQuizResults(patientId);
        if (response.status === 200 && response.headers['content-type'] && response.headers['content-type'].includes('application/json')){
          this.canDoctorView = response.data.canDoctorView;
          return response.data.resultData;
        }
      } catch (error) {
        console.error(error);
        return null;
      }
    },

    async fetchQuizResults() {
      let fetchedQuizResults = await this.getPatientQuizResults(this.patientId);
      if(fetchedQuizResults){
        this.quizTaken = true;
      }
    },

    handleSelectedProductUpdate(updatedProduct) {
      this.$emit('update:presetselectedProduct', updatedProduct);
    },

    handleSelectedProductsUpdate(updatedProducts) {
      this.$emit('update:presetselectedProducts', updatedProducts);
    },

    removeProduct(productId) {
      this.productIds.push(productId);
      this.setProductIdsToRemove(this.productIds);
      this.openProductModal(productId, this.systemProducts);
    },

    filterCreators(creators) {
      this.addCreatorFilter(creators);
    },
    getPracticeId() {
      const id = this.patient && this.patient.practice ? this.patient.practice.id : this.practice.id;
      if (this.creators == 'nan') {
        this.creators = id.toString();
      }
      return id.toString();
    }
  }
};
</script>