<template>
  <div class="pagination-wrap">
    <div class="pagination-l">
      <div 
        v-show="!hidePerPage"
        class="page-results"
      >
        Display
        <select 
          v-model="perPageLocal"
          @change="changePerPage"
        >
          <option value="10">
            10
          </option>
          <option value="20">
            20
          </option>
          <option value="30">
            30
          </option>
          <option value="40">
            40
          </option>
          <option value="50">
            50
          </option>
        </select>
        results per page
      </div>
    </div>
    <div class="pagination-r">
      <nav class="pagination">
        <p v-if="totalRows < 1" class="pag-viewing">
          Viewing 0 - 0 of 0
        </p>
        <p v-else class="pag-viewing">
          Viewing 
          {{ currentPage == 1 ? currentPage : (currentPage - 1) * perPage + 1 }} - 
          {{ currentPage * perPage < totalRows ? currentPage * perPage : totalRows }} of 
          {{ totalRows }}
        </p>
        <ul 
          v-if="totalPages > 1" 
          class="pagination"
        >
          <li>
            <a 
              class="hover-btn"
              :class="currentPage == 1 ? 'disabled' : ''"
              @click="changePage(1)" 
            >
              <i class="fas fa-angle-double-left" />
            </a>
          </li>
          <li>
            <a
              class="hover-btn"
              :class="currentPage == 1 ? 'disabled' : ''"
              @click="changePage(currentPage-1)" 
            >
              <i class="fas fa-angle-left" />
            </a>
          </li>
          <li 
            v-if="currentPage > 2" 
            role="separator" 
            class="disabled"
          >
            <span>…</span>
          </li>
          <li 
            v-if="currentPage > 1" 
            class="page-item active"
          >
            <a 
              class="hover-btn"
              @click="changePage(currentPage-1)"
            >
              {{ currentPage - 1 }}</a>
          </li>
          <li>
            <a class="disabled active hover-btn">{{ currentPage }}</a>
          </li>
          <li 
            v-if="currentPage < totalPages"
          >
            <a 
              class="hover-btn"
              @click="changePage(currentPage + 1)"
            >
              {{ currentPage + 1 }}</a>
          </li>
          <li 
            v-if="currentPage + 1 < totalPages"
          >
            <a 
              class="hover-btn"
              @click="changePage(currentPage + 2)"
            >
              {{ currentPage + 2 }}</a>
          </li>
          <li 
            v-if="currentPage + 2 < totalPages" 
            role="separator" 
            class="disabled"
          >
            <span>…</span>
          </li>
          <li>
            <a 
              :class="currentPage == totalPages ? 'disabled' : ''"
              class="hover-btn"
              @click="changePage(currentPage + 1)" 
            >
              <i class="fas fa-angle-right" />
            </a>
          </li>
          <li>
            <a 
              :class="currentPage == totalPages ? 'disabled' : ''"
              class="hover-btn"
              @click="changePage(totalPages)" 
            >
              <i class="fas fa-angle-double-right" />
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <!-- /. pagination-wrap -->
</template>

<script>
// @group Partials
export default {
  name: 'Pagination',
  data() {
    return {
      perPageLocal: 20,
    };
  },
  watch: {
    perPage: function (newVal) {
      this.perPageLocal = newVal;
    }
  },
  props: ['totalRows', 'perPage', 'currentPage', 'totalPages', 'hidePerPage'],
  methods: {
    // @vuese
    // Used to change per page in pagination and emit new value to parent
    // @arg Per page value `Number`
    changePerPage() {
      this.$emit('change-per-page', this.perPageLocal);
    },
    // @vuese
    // Used to change page in pagination
    // @arg Page number
    changePage(page) {
      this.$emit('change-page', page);
    }
  }
};
</script>